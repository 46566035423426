#threeJsWrapper {
	width: 300px !important;
	height: 300px !important;
	align-self: center;

	canvas {
		width: 300px !important;
		height: 300px !important;
		outline: none !important;
		border: none !important;
	}

	animation: showSquare 4s linear;
}

@keyframes showSquare {
	1% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}

}
