.cesium-viewer {
  @media(max-width: 992px){
    top: 50px;
    bottom: 55px;
    width: Calc(100vw - 0px);
    height: Calc(100vh - 30px);
    left: 0;
  }
  left: 87px;
  width: Calc(100vw - 87px);
  height: 100vh;
  background-color: black;
  cursor: pointer;
	transition: .6s;
}
.active {
  .cesium-viewer {
    left: 421px;
    width: Calc(100vw - 421px);
    @media(max-width: 992px){
      left: 0;
      width: 100vw;
    }
  }
}

.cesium-viewer-fullscreenContainer,
.cesium-viewer-bottom,
.cesium-viewer-toolbar,
.cesium-viewer-fullscreenContainer {
  display: none !important;
}
.map-drag {
  cursor: grabbing;
}
