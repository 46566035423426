.container {
  @media (max-width: 992px) {
    display: none;
  }
  height: 100vh;
  width: 87px;
  border-right: 4px solid #5c36be;
  background: #14105c;
  backdrop-filter: blur(16px);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
}

.zoomCircle{
  cursor: pointer;
  height: 38px;
  width: 38px;
  background: #E8E7EE;
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconChevron {
  transition: 0.6s;
  background: #F80183;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    transform: translateX(334px) scaleX(-1);
  }
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  z-index: 1000;
  right: -12px;
  top: 74px;
}

.aside {
  @media (max-width: 992px) {
    display: none;
  }
  transition: 0.6s;
  &.active {
    transform: translateX(0);
  }
  transform: translateX(-100%);
  position: absolute;
  top: 0;
  z-index: 5;
  left: 91px;
  width: 334px;
  height: 100vh;
  background: linear-gradient(180deg, #0a082f 0%, #4c2d9d 100%);
}

.iconWrapper {
  &.tabHeader{
    justify-content: flex-start;
  }
  position: relative;
  overflow: hidden;
  &:before {
    transition: 0.3s;
    left: 0;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    z-index: -2;
    transform: translateX(-100%);
    width: 100%;
    background: #5c36be;
  }
  &:hover {
    cursor: pointer;
  }
  &.active {
    &:before {
      transform: translateX(0%);
      transition: 0.3s;
    }
  }
  height: 52px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pluriusFull {
  margin: 45px 24px 0 24px;
}

.logoIcon {
  transition: 0.3s;
  &.disable {
    opacity: 0;
  }
  align-self: center;
  width: 29px;
  height: 23px;
  margin: 66px 0 25.5px;
}

.searchIcon {
  width: 24px;
  height: 21px;
}

.userIcon {
  width: 18px;
  height: 19px;
}

.vectorIcon {
  width: 21px;
  height: 21px;
}

.tabsPanelContainer {
  background-color: #1f2937ff;
  height: 100%;
}
.tabsPanelButtons {
  display: flex;
  background-color: #081c20;
  justify-content: space-between;
}

.tabsPanelBtn {
  margin: 0;
  width: 32%;
  font-size: 1rem;
  padding: 0.4rem;
  border-radius: 0.375rem 0.375rem 0 0;
  border-color: transparent;
  color: black;
  background-color: rgba(107, 114, 128, 0.4);
  transition: 0.3s;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
  &.active {
    color: white;
    background-color: #1f2937ff;
  }
}

.headerContainer {
  margin: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerButton {
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
}

.button {
  margin: 0.5rem 0;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgb(75, 85, 99);
  border-color: transparent;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    background-color: rgb(119, 126, 138);
  }
}

.reactSwitchContainer {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

.reactSwitchSpan {
  padding-left: 0.5rem;
  font-size: 1rem;
  color: white;
}

.tabContainer {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  color: white;
}

.label {
  display: block;
  color: white;
  font-weight: bolder;
  font-size: 1rem;
}

.inputNumber {
  width: 42%;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgb(75, 85, 99);
  border-color: transparent;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    background-color: rgb(119, 126, 138);
  }
}

.inputText {
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgb(75, 85, 99);
  border-color: transparent;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    background-color: rgb(119, 126, 138);
  }
}

.inputsContainer {
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
}

.headerButton {
  text-align: left;
  padding-left: 8px;
}

.headerH06 {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #e8e7ee;
}

.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #e8e7ee;
}

.addressLabel {
  margin: 45px 24px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #5c36be;
}

.coordinatesLabel {
  padding: 16px 24px 16px 24px;
}

.ownedByLabel {
  padding-top: 1rem;
  border-top: 1px solid #5c36be;
  margin: 16px 30px 16px 24px;
}

.addressContent {
  margin: 30px 24px 25px;
  word-wrap: anywhere;
  hyphens: auto;
}

.socialContainer {
  margin: 24px 24px 24px 24px;
}

.socialText {
  margin-bottom: 8px;
}

.socialButton {
  
  transition: 0.3s;
  padding:1rem 2rem;
  &.coordinate {
    color: #e8e7ee;
    width: 100%;
  }
  &:hover {
    background: #f80183;
    cursor: pointer;
    color: #e8e7ee;
  }
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(16px);
  border-radius: 100px;
  flex: none;
  order: 1;
  width: 220px;
  color: #a2aabe;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid transparent;
  box-shadow: 0px 4px 8px rgba(27, 27, 27, 0.16);
}

.bodyText {
  padding-bottom: 32px;
  border-bottom: 1px solid #5c36be;

  margin: -10px 24px 0 25px;
  text-align: left;
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
}

.inputsContainer {
  margin: 0 24px;
  justify-content: space-around;
}

.input {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: #e8e7ee;
  width: 129px;
  height: 52px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(16px);
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.24);
}

.btnResetZoom {
  &:hover {
    cursor: pointer;
  }
  text-decoration: underline;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #afa8be;
  margin: 16px 16px 0 16px;
  text-align: center;
  border-bottom: 1px solid #5c36be;
  padding-bottom: 16px;
}

.land {
  .iconThree,
  .landLabel {
    transition: 0.3s;
  }
  &:hover {
    .iconThree,
    .landLabel {
      opacity: 0;
    }
  }
  border-radius: 8px;
  margin: 0 24px;
  width: 286px;
  height: 291px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(16px);
  position: relative;
}

.landLabel {
  position: absolute;
  top: 20px;
  left: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #a2aabe;
  z-index: 20;
}

.iconThree {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.nftItem {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.landButtons {
  margin: 24px 24px 0px 24px;
  padding-bottom: 50px;
}

.landBtn {
  transition: 0.3s;
  &:hover {
    color: #14105c;
    background: #e8e7ee;
  }
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.09), 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  &.active {
    color: #14105c;
    background: #e8e7ee;
  }
  &:hover {
    cursor: pointer;
  }
  &:nth-child(1) {
    margin-right: 1rem;
  }
  border: 0 solid black;
  width: 68px;
  height: 33px;
  background: rgba(232, 231, 238, 0.2);
  border-radius: 100px;
  text-align: center;
  color: #e8e7ee;
}
.yLand {
  cursor: pointer;
  border-top: 1px solid #5c36be;
  margin: 0 16px;
  display: flex;
  align-items: center;
}

.yLandImg {
  padding: 16px 0;
  margin-right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.yLandLabel {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #e8e7ee;
  cursor: pointer;
}

.plusMinus {
  @media (max-width: 992px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 26px;
  right: 40px;
  z-index: 2;
  height: 100px;
  width: 46px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(50px);
  border-radius: 100px;
}

.localize {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 400;
  top: 66px;
  background: #e8e7ee;
  border-radius: 100px;
  right: -144px;
  width: 104px;
  height: 33px;
  @media (max-width: 992px) {
    left: 16px;
  }
}

.localizeLabel {
  color: #14105c;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  margin-left: 6px;
}

.rightUi {
  @media (max-width: 992px) {
    display: none;
  }
  display: flex;
  position: fixed;
  z-index: 2;
  background: #e8e7ee;
  top: 40px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  right: 40px;
  width: 228px;
  height: 46px;
}

.rightUiIcon {
  transition: 0.3s;

  .faIcon {
    transition: 0.3s;
  }
  &:hover {
    .faIcon {
      color: #e8e7ee;
    }
    background: #f80183;

    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 38px;
  background: #e8e7ee;
  border-radius: 100px;
  margin: 4px;
}

.rightUiBtn {
  transition: 0.3s;
  &:hover {
    span {
      color: #e8e7ee;
    }
    cursor: pointer;
    background: #f80183;
  }
  width: 120px;
  height: 38px;
  background: #e8e7ee;
  border-radius: 100px;
  margin: 4px 4px 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    transition: 0.3s;
    color: #f80183;
    text-align: center;
    vertical-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }
}

.navbarMobile {
  display: flex;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 50px;
}

.bottom {
  overflow: hidden;
  display: flex;
  background: #e8e7ee;
  backdrop-filter: blur(50px);
  border-radius: 10px 10px 0px 0px;
  height: 55px;
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.bottomBlock {
  transition: 0.3s;
  &.active {
    background: #f80183;
    .faIcon {
      color: white;
    }
    .bottomLabel {
      color: white;
    }
  }
  position: relative;
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    &:before {
      z-index: -1;
      content: "";
      position: absolute;
      background: #bebebe;
      width: 1px;
      height: 38px;
    }
  }

  padding: 7px 0 10px;
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bottomLabel {
  text-align: center;
  color: #14105c;
  margin-top: 4px;
  font-size: 11px;
}

.mobileView {
  top: 0;
  transition: 0.5s;
  height: 100vh;
  position: fixed;
  background: linear-gradient(180deg, #0a082f 0%, #4c2d9d 100%);
  box-shadow: 0px -44px 18px rgba(0, 0, 0, 0.01),
    0px -25px 15px rgba(0, 0, 0, 0.05), 0px -11px 11px rgba(0, 0, 0, 0.09),
    0px -3px 6px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  z-index: 500;
  left: 0;
  right: 0;
  padding: 98px 16px 0 16px;
  &.active {
    transform: translateY(0%);
  }

  transform: translateY(100%);
}

.mobileUser {
  display: flex;
  align-items: flex-start;
}

.desktopUser {
  margin: 0 20px;
  display: flex;
  align-items: flex-start;
}

.mobileUserText {
  margin: 4px 0 0 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  span {
    font-weight: 400;
  }

  color: #E8E7EE;

}

.mobileViewIcon {
  margin: 17px 11px 23px;
}

.mobileLand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 146px;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(16px);
  border-radius: 8px;
  border: 1px solid rgba(248,1,131,.24)
}

.mobileLandText {
  margin-left: 1rem;
  font-size: 16px;
  line-height: 19px;
  color: #a2aabe;
}

.mobileUserParagraph {
  display: flex;
  margin-top: 30px;
}

.mobileUserParagraphText {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
}

.opacityComponent {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  display: none;
  &.active {
    display: block;
  }
}