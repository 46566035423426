.toast{
  position: absolute;
}


.success{
  background: #F8FAFC;
  color: #424343;
  border: 2px solid #087A06;
}

.error{
  background: #F8FAFC;
  color: #424343;
  border: 2px solid #D00101;
}

.info{
  background: #F8FAFC;
  color: #424343;
  border: 2px solid #0F8DC2;
}

.warning{
  color: #424343;
  border: 2px solid #AC8D1D;
  background: #F8FAFC;
}

.close{
  &:hover{
    cursor: pointer;
  }
  border: 0;
  outline: 0;
  background: transparent;
}
